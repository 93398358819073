import { ExternalLink } from "@clipboard-health/ui-react";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";

import { getGroupAttendanceBonusSummary } from "./utils";

export function WorkWithFriendsInfoBox() {
  const {
    [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks,
    [CbhFeatureFlag.WORK_WITH_FRIENDS_BONUSES]: wwfBonuses,
  } = useCbhFlags();
  const { addFriendBonus, groupAttendanceBonus } = wwfBonuses || {};

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });
  return (
    <div className="friends-list-info-container" data-testid="friends-list-info-container">
      <p>You can work together with your friends through Clipboard.</p>
      <p>
        To do that, add them here to quickly see which open shifts they will be working on under
        “Find Shifts.”
      </p>
      <p>Only works for shifts that require more than one worker.</p>
      {addFriendBonus || groupAttendanceBonus ? (
        <p data-testid="friend-bonus-text">
          {getGroupAttendanceBonusSummary(
            addFriendBonus,
            groupAttendanceBonus,
            isClipboardScoreEnabled
          )}
        </p>
      ) : null}
      <ExternalLink to={supportLinks?.WORK_WITH_FRIENDS || DEFAULT_SUPPORT_LINKS.WORK_WITH_FRIENDS}>
        Learn more
      </ExternalLink>
    </div>
  );
}
